/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useApi } from '../hooks/useApi';
import toast from 'react-hot-toast';
export function AuthModal({ isOpen, onClose,  }) {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const { login } = useAuth();
    const { apiCall } = useApi();

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('keydown', handleEscape);
        }

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [isOpen, onClose]);

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/request-otp', {
                method: 'POST',
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                const data = await response.json();

                console.log(data);
                toast.success('Email sent successfully! Check your inbox for the code.');
                setStep(2);
            } else {
                toast.error('Failed to send email. Please try again.');
                console.error('Error sending email:', response.statusText);
            }
        } catch (error) {
            toast.error('Failed to send email. Please try again.');
            console.error('Error sending email:', error);
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/verify-otp', {
                method: 'POST',
                body: JSON.stringify({ email, otp }),
            });

            if (response.ok) {
                await login();
                handleStripeRedirect();
            } else {
                const data = await response.json();
                toast.error('Failed to verify OTP. Please try again.');
                console.error('Error verifying OTP:', data.error);
            }
        } catch (error) {
            toast.error('Failed to verify OTP. Please try again.');
            console.error('Error during OTP verification:', error);
        }

    };

    const handleStripeRedirect = async() => {
        // TODO: Implement Stripe subscription redirect
        const subscription = await apiCall('api/check_subscription', { method: 'POST' });
        if (subscription.ok) {
            toast.success('You are already subscribed!');
            onClose();
            return;
        }
        console.log('Redirecting to Stripe subscription page');
        const response = await apiCall('api/generate_checkout_session', { method: 'POST' });
        if (!response.ok) {
            toast.error('Failed to generate Stripe checkout session');
            return;
        }
        const responseJson = await response.json();
        const url = responseJson.redirectUrl;
        window.location.href = url;
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg max-w-md w-full">
                <div className="p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-semibold">
                            {step === 1 ? 'Welcome Back (or Get Started)!' : 'Check Your Inbox'}
                        </h2>
                        <button
                            onClick={onClose}
                            className="text-gray-500 hover:text-gray-700 transition-colors"
                        >
                            <X className="h-6 w-6" />
                        </button>
                    </div>
                    {step === 1 ? (
                        <form onSubmit={handleEmailSubmit} className="space-y-4">
                            <p className="text-sm text-gray-600">
                                Enter your email address to sign in or create an account. We'll send you a one-time passcode for quick verification.
                            </p>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <button
                                type="submit"
                                className="w-full bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 transition-colors"
                            >
                                Send Me the Code
                            </button>
                            {/* <p className="text-xs text-center text-gray-500">
                                We care about your privacy. <a href="#" className="underline">Privacy Policy</a>
                            </p> */}
                            <p className="text-xs text-center text-gray-500">
                                By continuing, you agree to our{' '}
                                <a 
                                    href="https://docs.google.com/document/d/115x6lgvZazr9jROndPabgDDZugaLc5sz5YENROFrSJ8/edit?usp=sharing" 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="underline"
                                >
                                    Terms and Conditions
                                </a>{' '}
                                and{' '}
                                <a 
                                    href="https://docs.google.com/document/d/1rTi4jDpreb4NMP28yoe5FtoJZfT1j8I5QRxqrViyM6U/edit?usp=sharing" 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="underline"
                                >
                                    Privacy Policy
                                </a>
                            </p>
                        </form>
                    ) : (
                        <form onSubmit={handleOtpSubmit} className="space-y-4">
                            <p className="text-sm text-gray-600">
                                We've sent a one-time passcode to your email. Please enter it below to verify your account.
                            </p>
                            <input
                                type="text"
                                placeholder="123456"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <button
                                type="submit"
                                className="w-full bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 transition-colors"
                            >
                                Verify Code
                            </button>
                            <button
                                type="button"
                                onClick={handleEmailSubmit}
                                className="w-full text-blue-600 hover:underline"
                            >
                                Resend Code
                            </button>
                            <p className="text-xs text-center text-gray-500">
                                Having trouble? Check your spam folder or contact support.
                            </p>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
}

