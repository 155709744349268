'use client'
/* eslint-disable */

import React, { useState } from 'react';
import { ArrowRightIcon, ThumbUpIcon, ThumbDownIcon } from '@heroicons/react/solid';
import { Loader2, User } from 'lucide-react';
import { FaTwitter } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import ExampleShortcuts from '../components/ExampleShortcuts';
import ShortcutsInfo from '../components/ShortcutsInfo';
import { useEffect } from 'react';
import { AuthModal } from '../components/AuthModal';
import { useAuth } from '../contexts/AuthContext';
import { useApi } from '../hooks/useApi';

function Home() {
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [shortcut, setShortcut] = useState(null);
  const [shortcutUrl, setShortcutUrl] = useState(null);
  const [error, setError] = useState(null);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [lastGeneratedPrompt, setLastGeneratedPrompt] = useState('');
  const [cancelUrl, setCancelUrl] = useState('');
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const { isAuthenticated, checkAuthStatus } = useAuth();
  const { apiCall } = useApi();
  const [pollingInterval, setPollingInterval] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    if (isAuthenticated === null) {
      checkAuthStatus();
    }
  }, [checkAuthStatus, isAuthenticated]);

  useEffect(() => {
    const checkSubscription = async () => {
      if (isAuthenticated) {
        const subscription = await apiCall('api/check_subscription', { method: 'POST' });
        setIsSubscribed(subscription.ok);
      }
    };
    checkSubscription();
  }, [isAuthenticated]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await generateShortcut(prompt);
  };

  const handleCancel = () => {
    setIsLoading(false);
    localStorage.removeItem('processId'); // Remove processId only on cancel
    setShortcut(null);
    setShortcutUrl(null);
    setError(null);
    toast.success('Cancelling shortcut generation...');
  };

  const generateShortcut = async (shortcutPrompt) => {
    if (!isAuthenticated) {
      setIsAuthModalOpen(true);
      return;
    }

    const subscription = await apiCall('api/check_subscription', { method: 'POST' });
    if (!subscription.ok) {
      setIsLoading(false);
      setShortcut(null);
      setShortcutUrl(null);
      toast.error('You need to subscribe to generate shortcuts');
      console.log('Redirecting to Stripe subscription page');
      const response = await apiCall('api/generate_checkout_session', { method: 'POST' });
      if (!response.ok) {
        toast.error('Failed to generate Stripe checkout session');
        return;
      }
      const responseJson = await response.json();
      const url = responseJson.redirectUrl;
      window.location.href = url;
      return;
    }

    setIsLoading(true);
    setError(null);
    setShortcut(null);
    setShortcutUrl(null);
    try {
      const response = await apiCall('api/generate_shortcut', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ prompt: shortcutPrompt }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to initiate shortcut generation');
      }

      const processId = data.processId;
      localStorage.setItem('processId', processId);
      localStorage.setItem('lastGeneratedPrompt', shortcutPrompt);
      setLastGeneratedPrompt(shortcutPrompt);
      toast.success('Shortcut generation initiated! You can check the status later.');

      startPolling(processId);

      window.sa_event('shortcut_started', { shortcutPrompt, processId });

    } catch (err) {
      setIsLoading(false);
      setError(err.message || 'Failed to start shortcut generation. Please modify your prompt and try again.');
      setShortcut(null);
      setShortcutUrl(null);
      console.error(err);
      toast.error('Failed to start shortcut generation');
      window.sa_event('shortcut_failed', { shortcutPrompt, error: err.message });
    }
  };
  const startPolling = (processId) => {
    const intervalId = setInterval(() => {
      checkShortcutStatus(processId, intervalId);
    }, 5000);

    // Return a cleanup function to clear the interval
    return () => clearInterval(intervalId);
  };

  const checkShortcutStatus = async (processId, intervalId) => {
    try {
      const response = await fetch('/api/check_shortcut_status', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ processId }),
      });

      if (!response.ok) {
        throw new Error('Failed to check shortcut status');
      }

      const responseJson = await response.json();
      
      if (responseJson.status === 'processing') {
        console.log('Shortcut is still processing...');
        return 'processing';
      }

      if (responseJson.status === 'failed') {
        console.error('Shortcut generation failed');
        setError('Failed to generate shortcut');
        setIsLoading(false);
        if (intervalId) {
          clearInterval(intervalId);
          setPollingInterval(null);
        }
        localStorage.removeItem('processId');
        toast.error('Shortcut generation failed');
        return 'failed';
      }

      if (responseJson.status === 'successed') {
        const jsonOutput = JSON.parse(responseJson.result.result);
        console.log('Shortcut is ready:', jsonOutput);
        setShortcut(jsonOutput.r2Path);
        setShortcutUrl(jsonOutput.publicUrl);
        setIsLoading(false);

        if (intervalId) {
          clearInterval(intervalId);
          setPollingInterval(null);
        }

        toast.success('Shortcut is ready!');
        window.sa_event('shortcut_ready', { processId, shortcut: jsonOutput.r2Path });
        return 'succeeded';
      }
    } catch (err) {
      console.error(err);
      setError(err.message || 'An unexpected error occurred');
      toast.error('Failed to check shortcut status');
      window.sa_event('shortcut_status_failed', { processId, error: err.message });
      throw err;
    }
  };

  useEffect(() => {
    const processId = localStorage.getItem('processId');
    const lastGeneratedPrompt = localStorage.getItem('lastGeneratedPrompt');

    if (processId) {
      console.log(`Resuming status check for process ID: ${processId}`);
      setIsLoading(true);
      setLastGeneratedPrompt(lastGeneratedPrompt || '');

      // Initial check immediately
      checkShortcutStatus(processId)
        .then(status => {
          if (status === 'processing') {
            // Only start polling if still processing
            const interval = setInterval(() => {
              checkShortcutStatus(processId, interval);
            }, 5000);
            setPollingInterval(interval);
          }
        })
        .catch(error => {
          console.error('Error checking shortcut status:', error);
          setIsLoading(false);
        });

      // Cleanup function
      return () => {
        if (pollingInterval) {
          clearInterval(pollingInterval);
        }
      };
    }
  }, []);

  const handleShare = () => {
    const tweetText = encodeURIComponent(
      `I just generated a custom Apple Shortcut! Check it out: ${window.location.href}`
    );
    window.sa_event('shared_shortcut', { shortcut });
    window.open(`https://twitter.com/intent/tweet?text=${tweetText}`, '_blank');
  };

  const handleCreateExampleShortcut = async (exampleTitle) => {
    setPrompt(exampleTitle);
    await generateShortcut(exampleTitle);
  };

  const handleAccount = async () => {
    if (!isAuthenticated) {
      toast.success('Please log in to manage your account');
      window.sa_event('account_manage_login');
      setIsAuthModalOpen(true);
      return;
    }

    window.sa_event('account_manage_open');
    const response = await apiCall('api/generate_portal_session', { method: 'POST' });
    if (!response.ok) {
      toast.error('Failed to generate portal session');
      return;
    }
    const responseJson = await response.json();
    const url = responseJson.portalUrl;
    window.location.href = url;
  };

  return (
    <main className="min-h-screen bg-[#F8F9FB] flex flex-col justify-center items-center py-12 px-4">
      <div className="w-full max-w-2xl mx-auto">
        <div className="text-center mb-12 relative">
          {/* <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
            <span className="inline-block bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-full">
              BETA
            </span>
          </div> */}
          <h1 className="text-4xl sm:text-5xl font-bold mb-4 text-[#1C1C1E]">
            Generate Your Custom Apple Shortcut
          </h1>
          <p className="text-lg text-[#6B7280]">
            Type your request, and we'll instantly generate a personalized Apple Shortcut just for you.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="mb-8">
          <div className="flex flex-col gap-3">
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Describe your shortcut here..."
              className="w-full px-4 py-2 rounded-lg border border-[#E5E7EB] bg-white focus:outline-none focus:ring-2 focus:ring-[#6366F1] focus:border-transparent resize-none h-32"
            />
            <button
              type="submit"
              disabled={isLoading || !prompt.trim()}
              className="w-full sm:w-auto px-4 py-2 bg-black text-white rounded-lg font-medium flex items-center justify-center gap-2 hover:bg-gray-800 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? 'Generating...' : (shortcut && prompt === lastGeneratedPrompt ? 'Regenerate' : 'Generate')}
              <ArrowRightIcon className="w-4 h-4" />
            </button>
            {(!isAuthenticated || !isSubscribed) && (
              <p className="text-sm text-gray-500 text-center">
                Email required • Free trial available • Cancel anytime
              </p>
            )}
          </div>
        </form>

        {isLoading && (
          <div className="mb-8 p-6 bg-white rounded-xl shadow-sm flex items-center justify-center">
            <div className="flex flex-col items-center">
              <Loader2 className="w-12 h-12 text-[#6366F1] animate-spin mb-4" />
              <h3 className="text-xl font-semibold mb-2">Generating Your Shortcut</h3>
              <p className="text-[#6B7280] text-center mb-4">
                Your request has been scheduled and may take up to 5 minutes to complete.
                Please wait while we craft your personalized shortcut.
              </p>
              <button
                onClick={handleCancel}
                disabled={!isLoading}
                className="px-4 py-2 bg-red-500 text-white rounded-lg font-medium hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {'Cancel Generation'}
              </button>
            </div>
          </div>
        )}
     
        <div>
          <button
            onClick={handleAccount}
            className="w-full px-4 py-2 bg-black text-white rounded-lg font-medium flex items-center justify-center gap-2 hover:bg-gray-800 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Manage My Account
            <User className="w-4 h-4" />
          </button>
        </div>

        {error && (
          <div className="mb-8 p-4 bg-red-50 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        {shortcut && !isLoading && (
          <div className="bg-white rounded-xl p-8 mb-8 flex flex-col items-center">
            <div className="w-16 h-16 bg-[#6366F1] rounded-2xl flex items-center justify-center mb-4">
              <svg
                className="w-10 h-10 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
            </div>
            <h2 className="text-xl font-semibold mb-4">{shortcut}</h2>
            <button
              onClick={() => {
                // Open the shortcut in a new tab
                window.sa_event('shortcut_downloaded', { shortcut });
                window.open(shortcutUrl, '_blank');
              }}
              className="px-4 py-2 bg-[#F3F4F6] text-[#374151] rounded-lg font-medium hover:bg-gray-100 transition-colors">
              Download Shortcut
            </button>
            <div className="flex flex-col items-center gap-2 mt-4">
              <button
                onClick={() => window.location.href = '/chat'}
                className="px-4 py-2 bg-[#F3F4F6] text-[#374151] rounded-lg font-medium hover:bg-gray-100 transition-colors"
              >
                Chat with AI
              </button>
              <p className="text-sm text-gray-500 text-center max-w-sm mt-2">
                Engage with AI to explore your shortcut, request changes, and download updated versions.
              </p>
            </div>
          </div>
        )}

        {shortcut && !isLoading && (
          <div className="flex justify-center gap-2 mb-8">
            <button
              onClick={() => {
                setLiked(true);
                setDisliked(false);

                toast.success('Thanks for your feedback!');
                window.sa_event('liked_shortcut', { shortcut });
              }}
              className={`px-4 py-2 rounded-lg font-medium flex items-center gap-2 ${liked ? 'bg-black text-white' : 'bg-white text-black'
                }`}
            >
              <ThumbUpIcon className="w-4 h-4" />
              Like
            </button>
            <button
              onClick={() => {
                setDisliked(true);
                setLiked(false);
                toast.success('Thanks for your feedback!');
                window.sa_event('disliked_shortcut', { shortcut });
              }}
              className={`px-4 py-2 rounded-lg font-medium flex items-center gap-2 ${disliked ? 'bg-black text-white' : 'bg-white text-black'
                }`}
            >
              <ThumbDownIcon className="w-4 h-4" />
              Dislike
            </button>
            <button
              onClick={handleShare}
              className="px-4 py-2 bg-white text-black rounded-lg font-medium flex items-center gap-2"
            >
              <FaTwitter className="w-4 h-4" />
              Tweet This
            </button>
          </div>
        )}

        <ExampleShortcuts onCreateShortcut={handleCreateExampleShortcut} />

        <ShortcutsInfo />

        <footer className="text-center mt-8">
          {/* <a
            href="https://twitter.com/betterMateusz"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#6B7280] hover:text-[#4B5563] transition-colors"
          >
            Follow me on Twitter
          </a> */}
          <p className="text-[#6B7280] mt-1">
            Made with ❤️ by{' '}
            <a
              href="https://twitter.com/betterMateusz"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#6B7280] hover:text-[#4B5563] transition-colors"
            >
              Mateusz
            </a>
          </p>
        </footer>
        <AuthModal isOpen={isAuthModalOpen} onClose={() => setIsAuthModalOpen(false)} />
      </div>
      <Toaster position="bottom-center" />
    </main>
  );
}

export default Home;

