/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useApi } from '../hooks/useApi';
import { Loader2, Download, ArrowLeft, Send, MessageSquare, Code } from 'lucide-react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

export default function Chat() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentShortcutJson, setCurrentShortcutJson] = useState(null);
  const { user } = useAuth();
  const { apiCall } = useApi();
  const messagesEndRef = useRef(null);
  const [shortcuts, setShortcuts] = useState([]);
  const [processId, setProcessId] = useState(localStorage.getItem('processId'));

  // New state for JSON view mode toggle: 'raw' or 'viz'
  const [jsonViewMode, setJsonViewMode] = useState('raw');

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    let mounted = true;

    const fetchShortcuts = async () => {
      try {
        const response = await apiCall('api/shortcuts', { method: 'GET' });
        if (response.ok && mounted) {
          const data = await response.json();
          setShortcuts(data.shortcuts);
          
          // If we have a processId, find and set the current shortcut JSON
          if (processId) {
            const currentShortcut = data.shortcuts.find(s => s.process_id === processId);
            if (currentShortcut?.result) {
              try {
                const parsedResult = JSON.parse(currentShortcut.result);
                setCurrentShortcutJson(parsedResult.shortcutJson);
              } catch (e) {
                console.error('Failed to parse shortcut JSON:', e);
              }
            }
          }
        }
      } catch (error) {
        console.error('Failed to fetch shortcuts:', error);
      }
    };

    fetchShortcuts();

    return () => {
      mounted = false;
    };
  }, [processId]);

  // Always update currentShortcutJson to the latest message from assistant that has shortcutData
  useEffect(() => {
    const lastShortcutMsg = [...messages].reverse().find(msg => msg.shortcutData && msg.shortcutData.json);
    if (lastShortcutMsg) {
      let shortcutJson = lastShortcutMsg.shortcutData.json;
      if (typeof shortcutJson === 'string') {
        try {
          shortcutJson = JSON.parse(shortcutJson);
        } catch (e) {
          console.error('Error parsing shortcut JSON:', e);
        }
      }
      setCurrentShortcutJson(shortcutJson);
    }
  }, [messages]);

  const handleShortcutSelect = async (newProcessId) => {
    localStorage.setItem('processId', newProcessId);
    setProcessId(newProcessId);
    setMessages([]); // Clear current messages temporarily while loading
    
    try {
      const response = await apiCall('api/chat_history', {
        method: 'POST',
        body: JSON.stringify({ processId: newProcessId })
      });
      if (response.ok) {
        const data = await response.json();
        setMessages(data.messages || []);
      } else {
        console.error('Failed to fetch message history');
      }
    } catch (error) {
      console.error('Error fetching message history:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    const processId = localStorage.getItem('processId');
    const userMessage = {
      id: Date.now(),
      text: newMessage,
      sender: user.email,
      timestamp: new Date().toISOString(),
    };

    setMessages(prev => [...prev, userMessage]);
    setNewMessage('');
    setIsLoading(true);

    try {
      const response = await apiCall('api/chat', {
        method: 'POST',
        body: JSON.stringify({
          message: newMessage,
          processId: processId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to get response');
      }

      const data = await response.json();

      const newMsg = {
        id: Date.now(),
        text: data.message,
        sender: 'assistant',
        timestamp: new Date().toISOString(),
        shortcutData: data.shortcutData
      };
      setMessages(prev => [...prev, newMsg]);
      if (newMsg.shortcutData && newMsg.shortcutData.json) {
        let shortcutJson = newMsg.shortcutData.json;
        if (typeof shortcutJson === 'string') {
          try {
            shortcutJson = JSON.parse(shortcutJson);
          } catch (e) {
            console.error('Error parsing shortcut JSON:', e);
          }
        }
        setCurrentShortcutJson(shortcutJson);
      }
    } catch (error) {
      setMessages(prev => [...prev, {
        id: Date.now(),
        text: 'Sorry, I encountered an error processing your request.',
        sender: 'assistant',
        timestamp: new Date().toISOString(),
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const MessageContent = ({ text = '', shortcutData = null }) => {
    if (shortcutData) {
      return (
        <div className="bg-white rounded-xl p-6 flex flex-col items-center shadow-sm border border-[#E5E7EB]">
          <div className="w-16 h-16 bg-[#6366F1] rounded-2xl flex items-center justify-center mb-4">
            <svg
              className="w-10 h-10 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
          </div>
          <h3 className="text-lg font-semibold mb-2">Shortcut Generated!</h3>
          <p className="text-center mb-4 text-gray-600">{text}</p>
          <button
            onClick={() => window.open(shortcutData.downloadUrl, '_blank')}
            className="px-6 py-3 bg-[#F3F4F6] text-[#374151] rounded-lg font-medium hover:bg-gray-100 transition-colors flex items-center gap-2 shadow-sm"
          >
            <Download className="w-5 h-5" />
            Download Modified Shortcut
          </button>
        </div>
      );
    }

    const html = DOMPurify.sanitize(marked(text));
    return <div className="prose prose-slate max-w-none" dangerouslySetInnerHTML={{ __html: html }} />;
  };

  // New recursive component for visualizing JSON as a tree
  const JsonTreeView = ({ data }) => {
    if (typeof data !== 'object' || data === null) {
      return <span className="text-sm">{data?.toString()}</span>;
    }
    if (Array.isArray(data)) {
      return (
        <ul className="list-disc ml-4">
          {data.map((item, index) => (
            <li key={index}>
              <JsonTreeView data={item} />
            </li>
          ))}
        </ul>
      );
    }
    return (
      <ul className="list-disc ml-4">
        {Object.entries(data).map(([key, value]) => (
          <li key={key}>
            <span className="font-semibold">{key}:</span> <JsonTreeView data={value} />
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="flex h-screen bg-[#F8F9FB]">
      {/* Left Sidebar - Shortcuts List */}
      <div className="hidden md:flex w-64 bg-white shadow-md border-r border-[#E5E7EB] flex-shrink-0 flex flex-col">
        <div className="h-[60px] px-4 border-b border-[#E5E7EB] flex items-center gap-3">
          <button
            onClick={() => window.location.href = '/'}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <ArrowLeft className="w-5 h-5 text-gray-600" />
          </button>
          <h2 className="text-lg font-semibold text-[#1C1C1E]">My Shortcuts</h2>
        </div>
        <div className="flex-1 overflow-y-auto p-4">
          {shortcuts.map((shortcut) => (
            <button
              key={shortcut.process_id}
              onClick={() => handleShortcutSelect(shortcut.process_id)}
              className={`w-full text-left p-3 rounded-lg mb-2 hover:bg-[#F3F4F6] transition-colors ${
                processId === shortcut.process_id ? 'bg-[#F3F4F6]' : ''
              }`}
            >
              <div className="font-medium truncate">
                {shortcut.shortcut_name || 'Untitled Shortcut'}
              </div>
              <div className="text-xs text-gray-500">
                {shortcut.created_at
                  ? new Date(shortcut.created_at).toLocaleDateString()
                  : 'Unknown date'}
              </div>
              <div className="text-xs text-gray-400 truncate">
                {shortcut.prompt}
              </div>
            </button>
          ))}
        </div>
      </div>

      {/* Main Chat Area */}
      <div className="flex-1 flex flex-col">
        {/* Header */}
        <div className="h-[60px] px-4 md:px-6 bg-white shadow-sm border-b border-[#E5E7EB] flex items-center">
          <h1 className="text-lg font-semibold text-[#1C1C1E]">Chat Assistant</h1>
        </div>

        {/* Messages Container */}
        <div className="flex-1 overflow-y-auto px-4 py-8 md:px-6">
          {messages.length === 0 ? (
            <div className="h-full flex flex-col items-center justify-center text-gray-500">
              <MessageSquare className="w-12 h-12 mb-4 text-gray-400" />
              <p className="text-lg font-medium mb-2">Welcome to Chat Assistant</p>
              <p className="text-sm text-center max-w-md">
                Ask questions about your shortcut, request modifications, or explore new possibilities.
              </p>
            </div>
          ) : (
            <div className="max-w-3xl mx-auto space-y-6">
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`flex ${message.sender === user.email ? 'justify-end' : 'justify-start'}`}
                >
                  <div
                    className={`max-w-[80%] md:max-w-[70%] ${
                      message.sender === user.email
                        ? 'bg-black text-white'
                        : 'bg-[#F3F4F6] text-[#1C1C1E]'
                    } rounded-xl px-4 py-3 shadow-sm`}
                  >
                    {message.shortcutData ? (
                      <MessageContent text={message.text} shortcutData={message.shortcutData} />
                    ) : (
                      <div className={`prose ${message.sender === user.email ? 'prose-invert' : 'prose-slate'} max-w-none`}>
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(message.text)) }} />
                      </div>
                    )}
                    <div
                      className={`text-xs mt-2 ${
                        message.sender === user.email ? 'text-gray-300' : 'text-gray-500'
                      }`}
                    >
                      {new Date(message.timestamp).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <div className="border-t border-[#E5E7EB] p-3 bg-white">
          <div className="max-w-3xl mx-auto">
            <form onSubmit={handleSubmit} className="flex gap-2">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                disabled={isLoading}
                className="flex-1 px-4 py-3 bg-[#F3F4F6] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#6366F1] disabled:opacity-50 placeholder-gray-500"
                placeholder="Type your message..."
              />
              <button
                type="submit"
                disabled={isLoading || !newMessage.trim()}
                className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 min-w-[100px] justify-center"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    <span>Sending</span>
                  </>
                ) : (
                  <>
                    <Send className="w-5 h-5" />
                    <span>Send</span>
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Right Sidebar - JSON View with Toggle */}
      <div className="hidden lg:flex w-[550px] bg-white shadow-md border-l border-[#E5E7EB] flex-shrink-0 flex flex-col">
        <div className="h-[60px] px-4 border-b border-[#E5E7EB] flex items-center justify-between">
          <h2 className="text-lg font-semibold text-[#1C1C1E]">Shortcut JSON</h2>
          <div className="flex space-x-2">
            <button onClick={() => setJsonViewMode('raw')} className={`px-3 py-1 rounded ${jsonViewMode === 'raw' ? 'bg-gray-300' : 'bg-gray-100'}`}>Raw</button>
            <button onClick={() => setJsonViewMode('viz')} className={`px-3 py-1 rounded ${jsonViewMode === 'viz' ? 'bg-gray-300' : 'bg-gray-100'}`}>Visual</button>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto p-4">
          {currentShortcutJson ? (
            jsonViewMode === 'raw' ? (
              <pre className="text-sm bg-[#F3F4F6] p-4 rounded-lg overflow-x-auto">
                <code>{JSON.stringify(currentShortcutJson, null, 2)}</code>
              </pre>
            ) : (
              <div className="p-4">
                <JsonTreeView data={currentShortcutJson} />
              </div>
            )
          ) : (
            <div className="h-full flex flex-col items-center justify-center text-gray-500">
              <Code className="w-12 h-12 mb-4 text-gray-400" />
              <p className="text-sm text-center">
                Select a shortcut or make modifications to see the JSON structure
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 